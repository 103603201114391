import { Theme } from '@models/question/theme.model';
import { Category } from '@models/question/category.model';

export const numberToString = (number: number): string => {
  if (number < 0 || number > 25) return 'Number out of range';
  return String.fromCharCode('A'.charCodeAt(0) + number);
};

export const joinArray = (
  args: (string | undefined | null)[],
  separator = ', ',
): string => {
  return args?.filter((v) => v != null).join(separator);
};

export const isBase64String = (base64string: string): boolean => {
  const match = base64string.match(
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/,
  );
  if (match == null || match.length <= 0) return false;
  return true;
};

export const stringInjector = (
  template: string,
  pattern: string,
  newValue: string,
): string => {
  return template.replace(`{{${pattern}}}`, newValue).replace('  ', ' ').trim();
};

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const areArraysStringEqual = (
  array1: string[],
  array2: string[],
): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};

export const renderList = (
  values: Theme[] | Category[] | string[],
  key: string = 'name',
): string => {
  return joinArray(
    values?.map((x) => {
      if (typeof x === 'object' && x !== null) {
        if (Object.hasOwn(x, key)) {
          const z = x as { [key: string]: string };
          return z[key];
        }
        console.warn(`object has no property named ${key}`);
        return undefined;
      }
      return x;
    }),
  );
};
